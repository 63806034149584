import { Icon } from "@iconify/react";
import CardImage from "../CardImage";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import lS from "manager-local-storage";
import { Link } from "react-router-dom";
import { QueryContext } from "../../context/QueryContext";
import "./styles/Card.css";
import "./styles/popular.css";

function Card({ data, setup, handleLoad }) {
  const { thumbnail, name, site, popular, nick } = data;
  const { theme: t, autoTheme } = useContext(ThemeContext);
  const [favorite, setFavorite] = useState(false);
  const [showTempElement, setShowTempElement] = useState(false);

  const { setQuery } = useContext(QueryContext);

  const theme = t === "device" ? autoTheme : t;

  const favoriteKey = "mega-packs-favorites";

  useEffect(() => {
    const getFavorite = () => {
      const currentFavorites = lS.get(favoriteKey) ?? [];
      const isFavorite = currentFavorites.some((f) => f === nick);
      setFavorite(isFavorite);
    };
    getFavorite();
  }, [nick]);

  // const icon =
  //   type && type === "telegram" ? "simple-icons:telegram" : "simple-icons:mega";

  const handleFavorite = () => {
    const currentFavorites = lS.get(favoriteKey) ?? [];
    if (favorite) {
      const removedFavorite = currentFavorites.filter((f) => f !== nick);
      if (removedFavorite.length === 0) {
        lS.remove(favoriteKey);
      }
      lS.set(favoriteKey, removedFavorite);
      setFavorite(false);
    } else {
      currentFavorites.push(nick);
      lS.set(favoriteKey, currentFavorites);
      setFavorite(true);
    }
    setup();

    // Mostrar o elemento temporário
    setShowTempElement(true);
    // Esconder o elemento temporário após 1 segundo
    setTimeout(() => {
      setShowTempElement(false);
    }, 1000);
  };

  const classPopular = popular ? " --popular" : "";

  const [n] = nick.split(" ");

  return (
    <section
      className={`card${
        favorite ? " --favorite" : ""
      }${classPopular} bg-${theme}`}
      onDoubleClick={handleFavorite}
    >
      <CardImage
        images={thumbnail}
        // onDbClick={handleFavorite}
        favorite={favorite}
        showTempElement={showTempElement}
        handleLoad={handleLoad}
      />
      {favorite && (
        <div className={`card__icons bg-${theme}`}>
          <span className={`card__icons__icon`}>
            <Icon icon="material-symbols:heart-check-rounded" />
          </span>
        </div>
      )}
      <div className="card__info">
        <h3 className={`card__info__name c-${theme}`}>
          {name}
          <Icon icon="mdi:tick" className="card__info__name__verified" />
        </h3>
        {/* <div className="card__tags">
            <Icon
              icon="tabler:square-rounded-letter-e"
              className="explicit"
            />
            <Icon icon="tabler:square-rounded-letter-v" className="video" />
            <Icon icon="tabler:square-rounded-letter-g" className="gb" />
          </div> */}
        <span className="card__info__site">{site}</span>
        <Link
          className="card__info__link"
          rel="noreferrer"
          to={`/pack/${n}`}
          onClick={() => setQuery("")}
        >
          Acessar
          {/* <Icon icon={icon} /> */}
        </Link>
      </div>
      {/* {showTempElement && (
        <div className="temp-element">
          {favorite && <Icon icon="material-symbols:heart-check-rounded" />}
          {!favorite && <Icon icon="material-symbols:heart-broken-outline" />}
        </div>
      )} */}
    </section>
  );
}

export default Card;
