import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import "./styles/Footer.css";

function Footer() {
  const { theme: t, autoTheme, handleSetTheme } = useContext(ThemeContext);

  const theme = t === "device" ? autoTheme : t;

  const isSelected = (THEME) => {
    return THEME === t ? " --selected" : "";
  };

  return (
    <footer className="footer">
      <div>
        <div className={`footer__theme__buttons bg-${theme}`}>
          <button
            className={`footer__theme__buttons__button ${isSelected("light")}`}
            onClick={() => handleSetTheme("light")}
          >
            <Icon icon="tdesign:mode-light" />
          </button>
          <button
            className={`footer__theme__buttons__button  ${isSelected(
              "device"
            )}`}
            onClick={() => handleSetTheme("device")}
          >
            <Icon icon="material-symbols:hdr-auto-outline" />
          </button>
          <button
            className={`footer__theme__buttons__button  ${isSelected("dark")}`}
            onClick={() => handleSetTheme("dark")}
          >
            <Icon icon="tdesign:mode-dark" />
          </button>
        </div>
      </div>
      <p>
        Este site contém conteúdo adulto e é destinado apenas a indivíduos com
        18 anos ou mais. Ao entrar neste site, você reconhece que é maior de
        idade e entende que o conteúdo pode ser ofensivo para alguns. O conteúdo
        deste site é apenas para uso pessoal e não deve ser compartilhado ou
        distribuído a menores de 18 anos.
      </p>
      <p>
        Todo o conteúdo disponível neste site é de acesso gratuito e não é
        carregado por nossa equipe. Não hospedamos nenhum conteúdo em nossos
        servidores. Se você suspeitar que seu material protegido por direitos
        autorais foi reproduzido de uma maneira que constitui violação de
        direitos autorais, entre em contato conosco pelo e-mail{" "}
        <span>packsonlyfree@gmail.com</span> para tomar as medidas apropriadas.
      </p>
      <h3>© Copyright 2024 MegaPacks, All rights reserved.</h3>
    </footer>
  );
}

export default Footer;
