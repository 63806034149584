import { useContext, useEffect, useState, useRef } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import data from "../../data/data.json";
import LazyImage from "../../components/LazyImage";
import { ThemeContext } from "../../context/ThemeContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import lS from "manager-local-storage";
import "./styles/Gallery.css";

function Gallery() {
  const zoomKey = "megapacks-zoom";
  const defaultZoom = lS.get(zoomKey) ?? 3;

  const [images, setImages] = useState([]);

  const { theme: t, autoTheme } = useContext(ThemeContext);
  const theme = t === "device" ? autoTheme : t;

  const [selectedImage, setSelectedImage] = useState(0);
  const [openView, setOpenView] = useState(false);
  const [packsView, setPacksView] = useState(1);
  const [columns, setColumns] = useState(defaultZoom);

  const lastImageRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    draggable: true,
    initialSlide: selectedImage,
    beforeChange: (oldIndex, newIndex) => {
      setSelectedImage(newIndex);
    },
  };

  const getImages = () => {
    const allImages = [];

    const slicedData = data.slice().reverse().slice(0, packsView);

    slicedData.forEach(({ thumbnail, nick, name, type }) => {
      const [firstNick] = nick.split(" ");

      thumbnail.forEach((src) =>
        allImages.push({ nick: firstNick, src, name, type })
      );
    });

    setImages(allImages);
  };

  useEffect(() => {
    getImages();
  }, [packsView]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setPacksView((prev) => prev + 1);
        }
      },
      {
        threshold: 0.1, // Trigger when at least 10% of the image is visible
      }
    );

    if (lastImageRef.current) {
      observer.observe(lastImageRef.current);
    }

    return () => {
      if (lastImageRef.current) {
        observer.unobserve(lastImageRef.current);
      }
    };
  }, [images]);

  const handleClickImage = (i) => {
    setSelectedImage(i);
    setOpenView(true);
  };

  const handleZoomOut = () => {
    if (columns > 1) {
      setColumns(columns - 1);
      lS.set(zoomKey, columns - 1);
    }
  };

  const handleZoomIn = () => {
    if (columns < 4) {
      setColumns(columns + 1);
      lS.set(zoomKey, columns + 1);
    }
  };

  return (
    <div>
      <Header />
      <Helmet>
        <title>Galeria</title>
      </Helmet>
      <>
        {openView && (
          <section className="gallery-view">
            <button
              className="gallery-view__close-btn"
              onClick={() => setOpenView(false)}
            >
              <Icon icon="mingcute:close-fill" />
            </button>
            <div className="gallery-view__slider">
              <Slider {...settings}>
                {images.map(({ src }, i) => (
                  <div key={i} className="card-image__image-wrapper">
                    <LazyImage
                      src={src}
                      alt={`Image ${i}`}
                      className="card-image__image"
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <Link
              to={`/pack/${images[selectedImage].nick}`}
              className={`c-${theme} gallery__view__title`}
            >
              <span>
                {images[selectedImage].name}
                <Icon
                  icon={
                    images[selectedImage].type &&
                    images[selectedImage].type === "telegram"
                      ? "simple-icons:telegram"
                      : "simple-icons:mega"
                  }
                />
              </span>
            </Link>
          </section>
        )}
        <section>
          <h2 className={`c-${theme} gallery__title`}>
            <Icon
              icon="ph:google-photos-logo-duotone"
              className={`bg-${theme}`}
            />
            <span>Galeria</span>
          </h2>
        </section>
        <section
          className="gallery"
          style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
        >
          {images.map(({ src, nick }, i) => {
            const isLastImage = i === images.length - 1;
            return (
              <LazyImage
                className="gallery__image"
                src={src}
                alt={`${nick}-${i}`}
                key={i}
                onClick={() => handleClickImage(i)}
                REF={isLastImage ? lastImageRef : null}
              />
            );
          })}
        </section>
        <div className="gallery-zoom">
          <button className={`bg-${theme}`} onClick={handleZoomIn}>
            <Icon icon="fa6-solid:minus" />
          </button>
          <button className={`bg-${theme}`} onClick={handleZoomOut}>
            <Icon icon="fa6-solid:plus" />
          </button>
        </div>
      </>
      <Footer />
    </div>
  );
}

export default Gallery;
